<template>
	<div class="video-page">
        <div class="video-content">
            <video width="100%" height="100%" :src="video_url" controls="controls">
            </video>
        </div>
        <div class="cancel-button" @click="closeVideo">
            <img src="https://qn.ky3d.com/multi-exhibition/cuowu2.png"/>
        </div>
	</div>
</template>

<script>

export default {
    props:{
        video_url: String
    },
    data(){
        return{

        }
    },
    created(){

    },
    methods:{
        closeVideo(){
            this.$emit('closeVideo')
        }
    }
}
</script>

<style>
    .video-page > .video-content{
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .video-page > .video-content > video{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
    .cancel-button{
        position: fixed;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
    }
    .cancel-button > img{
        width: 40px;
        height: 40px;
    }
</style>